<template>

  <div class="events" v-show="events.length">
    <div class="eventsElem" v-for="el in events">
      {{ el.message }}
    </div>
  </div>

  <div class="lost-error" v-show="!this.websocket || (this.websocket && !this.websocket.connectionStatus == 2)">
    <div class="lost-error-wrapper">

      <h2 class="lost-error-title" v-if="this.websocket && !this.websocket.connectionStatus == 2">Соединение потеряно
      </h2>
      <h2 class="lost-error-title" v-else>Идёт соединение с сервером</h2>

      <a class="lost-error-reload button" href="#" onclick="location.reload(); return false;">
        Перезагрузить страницу
      </a>
    </div>
  </div>

  <div class="modalEditTask" v-if="editTaskID">

    <div class="modalEditTaskContent">

      <svg @click="editTaskID = null" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
        fill="#000000">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <title>cross</title>
          <desc>Created with Sketch Beta.</desc>
          <defs> </defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
            <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-469.000000, -1041.000000)"
              fill="#000000">
              <path
                d="M487.148,1053.48 L492.813,1047.82 C494.376,1046.26 494.376,1043.72 492.813,1042.16 C491.248,1040.59 488.712,1040.59 487.148,1042.16 L481.484,1047.82 L475.82,1042.16 C474.257,1040.59 471.721,1040.59 470.156,1042.16 C468.593,1043.72 468.593,1046.26 470.156,1047.82 L475.82,1053.48 L470.156,1059.15 C468.593,1060.71 468.593,1063.25 470.156,1064.81 C471.721,1066.38 474.257,1066.38 475.82,1064.81 L481.484,1059.15 L487.148,1064.81 C488.712,1066.38 491.248,1066.38 492.813,1064.81 C494.376,1063.25 494.376,1060.71 492.813,1059.15 L487.148,1053.48"
                id="cross" sketch:type="MSShapeGroup"> </path>
            </g>
          </g>
        </g>
      </svg>


      <!-- <label class="modalEditTaskContentElem">
        Описание
        <textarea v-model="getTaskById(editTaskID).task_description"></textarea>
      </label>

      <label class="modalEditTaskContentElem">
        Описание
        <input type="number" />
      </label> -->


    </div>



  </div>


  <div class="app" v-if="this.websocket && this.websocket.connectionStatus == 1 && user">

    <div class="panel">

      <div class="panelHeader">
        <div @click="content = 'settings'">{{ user.login }}</div>
        <div>Баланс: {{this.user.balance}}</div>


        <div @click="content = 'tasks'">Задачи</div>
        <div @click="exit()">Exit</div>

      </div>
      <div class="panelFilters" v-if="content == 'tasks'">
        <div @click="tasksFilter = 'all'" :class="{ 'panelFiltersSelected': tasksFilter == 'all' }"
          class="panelFiltersElem">Все</div>
        <div v-if="isAdmin || isModer" @click="tasksFilter = 'new'"
          :class="{ 'panelFiltersSelected': tasksFilter == 'new' }" class="panelFiltersElem">Новая ({{
            getCountTasksInFilter('new') }}) {{ getCountTasksInFilterNew('new') }}
        </div>
        <div @click="tasksFilter = 'publish'" :class="{ 'panelFiltersSelected': tasksFilter == 'publish' }"
          class="panelFiltersElem">Опубликованы ({{ getCountTasksInFilter('publish') }}) {{
            getCountTasksInFilterNew('publish') }}</div>
        <div @click="tasksFilter = 'in_work'" :class="{ 'panelFiltersSelected': tasksFilter == 'in_work' }"
          class="panelFiltersElem">В работе ({{ getCountTasksInFilter('in_work') }}) {{
            getCountTasksInFilterNew('in_work') }}</div>
        <div @click="tasksFilter = 'check'" :class="{ 'panelFiltersSelected': tasksFilter == 'check' }"
          class="panelFiltersElem">На проверке ({{ getCountTasksInFilter('check') }}) {{
            getCountTasksInFilterNew('check') }}</div>

        <div v-if="isAdmin || isModer" @click="tasksFilter = 'sended'" :class="{ 'panelFiltersSelected': tasksFilter == 'sended' }"
          class="panelFiltersElem">Отправлены клиенту ({{ getCountTasksInFilter('sended') }}) {{
            getCountTasksInFilterNew('sended') }}</div>

        <div @click="tasksFilter = 'close_success'" :class="{ 'panelFiltersSelected': tasksFilter == 'close_success' }"
          class="panelFiltersElem">Закрыт успешно ({{ getCountTasksInFilter('close_success') }}) {{
            getCountTasksInFilterNew('close_success') }}</div>
        <div @click="tasksFilter = 'close_failed'" :class="{ 'panelFiltersSelected': tasksFilter == 'close_failed' }"
          class="panelFiltersElem">Закрыт неуспешно ({{ getCountTasksInFilter('close_failed') }}) {{
            getCountTasksInFilterNew('close_failed') }}</div>
      </div>

    </div>

    <div class="viewer">


      <div v-if="content == 'tasks'" class="content">

        <h2>Задачи</h2>

        <h4 class="accessCategoriesTitle" v-if="!isAdmin && getUserById(user.user_id)">Доступные категории</h4>

        <div class="accessCategories" v-if="!isAdmin && getUserById(user.user_id)">

          <div class="accessCategoriesElem" v-for="el in getUserById(user.user_id).categories">
            {{ getCategoryById(el).category_title }}
          </div>
        </div>

        <div class="contentTasks">

          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Статус</th>
                <th>Исполнитель</th>
                <th>Цена</th>
                <th>Категория</th>
                <th class="contentTasksHeaderDescription">Описание</th>
                <th>Файлы</th>
                <th>Дедлайн</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>

              <template v-for="(el, index) in tasksFiltered" :key="el.row_id">
                <tr :class="{ 'newTask': el.newTask }">
                  <th>{{ el.task_id }}</th>
                  <th>

                    <select v-model="el.status_task" @change="handleSelectChange($event, el.row_id)" v-if="el.status_task != 'close_success' && el.status_task != 'close_failed'">
                      <option v-if="isAdmin || isModer" value="new" selected>Новая</option>
                      <option v-if="!el.task_performer || (isAdmin || isModer)" value="publish">Опубликована</option>
                      <option v-if="canTakeTask || (el.task_performer == user.user_id && el.status_task != 'check')" value="in_work">В работе</option>
                      <option v-if="el.task_performer" value="check">На проверке</option>
                      <option v-if="el.task_performer && (isAdmin || isModer)" value="sended">Отправлен клиенту</option>
                      <option v-if="el.task_performer && (isAdmin || isModer)" value="close_success">Закрыт успешно</option>
                      <option v-if="el.task_performer && (isAdmin || isModer)" value="close_failed">Закрыт неуспешно</option>
                    </select>

                  </th>
                  <th>{{ el.task_performer && getUserById(el.task_performer) ? getUserById(el.task_performer).login : "" }}</th>

                  <th><span v-if="el.offer">{{ el.final_price }} (bs. {{ el.offer.cost }})</span></th>

                  <th>{{ getCategoryById(el.category_id).category_title }}</th>
                  <th class="contentTasksElemDescription">{{ el.task_description }}</th>
                  <th>
                    <div class="taskFiles">
                      <div class="taskFile" v-for="file in el.files">

                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="file.status == 0"
                          :title="`Файл ${file.fileName} в очереди`">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M9 15L11 17L15 13M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19"
                              stroke="#e3e3e3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                          </g>
                        </svg>

                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#59365e"
                          v-else-if="file.status == 1" :title="`Файл ${file.fileName} загружается`">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
                            stroke-width="0.048"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M9 15L11 17L15 13M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19"
                              stroke="#c7d411" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                          </g>
                        </svg>

                        <a :href="`${pathFiles}${file.file}.${file.extension}`" :download="`${file.fileName}`"
                          :title="`${file.fileName}`" v-else-if="file.status == 2">
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M9 15L11 17L15 13M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19"
                                stroke="#31c973" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </g>
                          </svg>
                        </a>

                      </div>
                    </div>
                  </th>
                  <th>
                    {{ el.time_left ? el.time_left : "" }}
                  </th>
                  <th>
                    <div class="taskActions">
                      <div class="taskActionsElem">
                        <svg v-if="!el.newMessage" @click="el.showDetails = !el.showDetails" viewBox="0 0 24 24"
                          fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13"
                              stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path>
                            <circle cx="12" cy="16" r="1" fill="#000000"></circle>
                            <path
                              d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                              stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path>
                          </g>
                        </svg>

                        <svg v-else @click="el.showDetails = !el.showDetails" fill="#ffbb00" width="207px"
                          height="207px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"
                          stroke="#ffbb00">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16.001-7.163 16.001-16s-7.163-16-16.001-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14.001 6.28 14.001 14-6.281 14.032-14.001 14.032zM14.53 25.015h2.516v-2.539h-2.516zM15.97 6.985c-1.465 0-2.672 0.395-3.62 1.184s-1.409 2.37-1.386 3.68l0.037 0.073h2.295c0-0.781 0.261-1.904 0.781-2.308s1.152-0.604 1.893-0.604c0.854 0 1.511 0.232 1.971 0.696s0.689 1.127 0.689 1.989c0 0.725-0.17 1.343-0.512 1.855-0.343 0.512-0.916 1.245-1.721 2.198-0.831 0.749-1.344 1.351-1.538 1.806s-0.297 1.274-0.305 2.454h2.405c0-0.74 0.047-1.285 0.14-1.636s0.36-0.744 0.799-1.184c0.945-0.911 1.703-1.802 2.277-2.674 0.573-0.87 0.86-1.831 0.86-2.881 0-1.465-0.443-2.607-1.331-3.424s-2.134-1.226-3.736-1.226z">
                            </path>
                          </g>
                        </svg>

                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                          @click="editTask(el.task_id)">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
                              fill="#0F0F0F"></path>
                          </g>
                        </svg>

                      </div>
                    </div>
                  </th>
                </tr>
                <tr v-if="el.showDetails">
                  <th colspan="100%">
                    <div class="taskDetails">
                      <div class="taskDetailsChat">

                        <div class="taskDetailsChatMessage"
                          :class="{ 'taskDetailsChatReview': message.user_id != this.user.user_id, 'taskDetailsChatQuestion': message.user_id == this.user.user_id }"
                          v-for="message in orderMessagesByRowID(el.messages)">

                          <div class="taskDetailsChatMessageSendler">
                            <div>{{ getUserById(message.user_id).login }}</div>
                            <div class="taskDetailsChatMessageSendlerDate">{{ formatDate(message.date_add) }}</div>
                          </div>

                          {{ message.text }}
                        </div>

                      </div>
                      <form class="taskDetailsSend">
                        <textarea placeholder="Напишите свой вопрос" type="text" v-model="el.question"></textarea>
                        <button @click.prevent="sendQuestion(el.task_id, el.question)">></button>
                      </form>
                    </div>
                  </th>
                </tr>
              </template>

            </tbody>
          </table>






        </div>

      </div>

      <div v-if="content == 'settings'" class="content">

        <h2>Настройки</h2>

        <div class="contentSettings">

          <h3>Изменить пароль</h3>

          <div class="contentSettingsPassword">
            <input placeholder="Новый пароль" type="password" v-model="newPassword">
            <button @click="changePassword()" :class="{ 'disabledBtn': !newPassword }">Изменить</button>
          </div>

        </div>

        <div class="contentSettings" v-if="user.root == 1">

          <h3>Пользователи</h3>

          <div class="contentSettingsUsers">

            <div class="contentSettingsUsersElem">
              <h4>Добавить пользователя</h4>
              <input placeholder="Логин" type="text" v-model="newUser.login">
              <input placeholder="Пароль" type="text" v-model="newUser.password">
              <select v-model="newUser.root">
                <option value="0">Пользователь</option>
                <option value="1">Админ</option>
                <option value="2">Moder</option>
              </select>

              <div class="newUserAccessCategory" v-if="newUser.root != 1">
                <div v-for="el in categories" class="newUserAccessCategoryElem"
                  :class="{ 'selectedElem': newUser.categories.includes(el.category_id) }"
                  @click="categoryForNewUser(el.category_id)">
                  {{ el.category_title }}
                </div>
              </div>

              <button @click="addNewUser()"
                :class="{ 'disabledBtn': !newUser.login || !newUser.password }">Создать</button>
            </div>

            <div v-for="el in allUsers" class="contentSettingsUsersElem">
              <input placeholder="Логин" type="text" v-model="el.newLogin">
              <input placeholder="Пароль" type="text" v-model="el.newPassword">
              <select v-model="el.newRoot">
                <option value="0">Пользователь</option>
                <option value="1">Админ</option>
                <option value="2">Moder</option>
              </select>

              <div class="newUserAccessCategory" v-if="el.newRoot != 1">
                <div v-for="cat in categories" class="newUserAccessCategoryElem"
                  :class="{ 'selectedElem': el.categories.includes(cat.category_id) }"
                  @click="categoryForUser(el.user_id, cat.category_id)">
                  {{ getCategoryById(cat.category_id).category_title }}
                </div>
              </div>

              <div class="contentSettingsUsersElemBtns">
                <!-- :class="{ 'disabledBtn': (el.login == el.newLogin) && !el.newPassword && (el.root == el.newRoot) }" -->
                <button @click="changeUser(el)">
                  Сохранить
                </button>
                <button class="btnNegative" @click="deleteUser(el)">
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                        stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg> </button>
              </div>
            </div>




          </div>

        </div>

      </div>

    </div>

  </div>

  <div v-if="!user" class="modal">
    <div class="modalContent">

      <div class="modalContentLoading" v-if="loadingEntry">
        Loading...
      </div>

      <form>
        <label class="modalContentElem">
          Логин
          <input type="text" v-model="login" />
        </label>

        <label class="modalContentElem">
          Пароль
          <input type="password" v-model="password" />
        </label>

        <button @click.prevent="entry" :class="{ 'disabledBtn': !password || !login }">
          Вход
        </button>
      </form>

      <div v-if="entryError" class="modalContentError">
        {{ entryError }}
      </div>

    </div>
  </div>

</template>
<script>
import { nextTick } from 'vue';
import axios from 'axios';

export default {
  components: {

  },
  data() {
    return {
      editTaskID: null,

      showDetails: null,

      pathFiles: "/uploads/files/",

      events: [],

      newPassword: null,

      categories: [],

      tasks: [],
      tasksFilter: 'all',

      users: [],

      newUser: {
        login: null,
        password: null,
        root: 0,
        categories: [],
      },

      newUserWait: false,

      login: null,
      password: null,
      loadingEntry: false,
      entryError: null,


      user: null,

      content: 'tasks',

      websocket: null, // Объект вебсокета
      intervalReconnection: null, // Interval переподключения к вебсокету, если не было получено ping

      interval: null,
    }
  },

  async mounted() {
    // Слушаем подключение/отключение интернета
    window.addEventListener('online', this.listenerOnline);
    window.addEventListener('offline', this.listenerOffline);

    this.mainFunctionApp();

    this.interval = setInterval(this.updateDeadline, 1000);
  },
  methods: {
    editTask(taskID) {
      this.editTaskID = taskID;
    },
    categoryForUser(userID, categoryID) {
      if (this.getUserById(userID).categories.includes(categoryID)) {
        this.getUserById(userID).categories = this.getUserById(userID).categories.filter(item => item !== categoryID);
      } else {
        this.getUserById(userID).categories.push(categoryID)
      }
    },
    categoryForNewUser(categoryID) {
      if (this.newUser.categories.includes(categoryID)) {
        this.newUser.categories = this.newUser.categories.filter(item => item !== categoryID);
      } else {
        this.newUser.categories.push(categoryID)
      }
    },
    formatDate(dateString) {
      const now = new Date();
      const date = new Date(dateString);

      // Проверяем, совпадает ли дата с сегодняшним днем
      const isToday = now.toDateString() === date.toDateString();

      // Форматируем дату в зависимости от условия
      if (isToday) {
        // Выводим часы и минуты
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
      } else {
        // Выводим число.месяц, часы:минуты
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}.${month}, ${hours}:${minutes}`;
      }
    },
    orderMessagesByRowID(messages) {
      return messages.sort((a, b) => b.message_id - a.message_id);
    },
    sendQuestion(taskID, question) {
      const message = {
        action: "sendQuestion",
        userID: this.user.user_id,
        question: question,
        taskID: taskID,
      }
      this.websocket.send(message);

      this.getTaskById(taskID).question = null;
    },
    handleSelectChange(event, rowID) {

      if (this.getTaskByRowId(rowID).status_task == "new" || this.getTaskByRowId(rowID).status_task == "publish") {
        this.getTaskByRowId(rowID).task_performer = 0;
      }

      if (this.getTaskByRowId(rowID).status_task == "in_work" && !this.getTaskByRowId(rowID).task_performer) {
        this.getTaskByRowId(rowID).task_performer = this.user.user_id;
      }

      this.changeTask(rowID);
    },
    changeTask(rowID) {
      const message = {
        action: "changeTask",
        task: this.getTaskByRowId(rowID),
      }
      this.websocket.send(message);
    },
    updateDeadline() {

      for (let i in this.tasks) {
        if (this.tasks[i].status_task == "close_failed" || this.tasks[i].status_task == "close_success") {
          this.tasks[i].time_left = null;
        } else {
          this.tasks[i].time_left = this.getDeadline(this.tasks[i].task_date_to);
        }
      }

    },

    getDeadline(targetDate) {

      const now = new Date();
      const target = new Date(targetDate);

      const diff = target - now;

      if (diff <= 0) {
        return '00:00';
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');

      if (days > 0) {
        return `${days}д. ${formattedHours}:${formattedMinutes}`;
      } else {
        return `${formattedHours}:${formattedMinutes}`;
      }
    },
    getCategoryById(categoryID) {
      for (let i in this.categories) {
        if (this.categories[i].category_id == categoryID) return this.categories[i];
      }
    },
    changePassword() {
      const message = {
        action: "changePassword",
        newPassword: this.newPassword,
      }
      this.websocket.send(message);
    },
    deleteUser(el) {
      const message = { action: "deleteUser", deleteUser: el }
      this.websocket.send(message);
    },
    changeUser(el) {
      const message = { action: "changeUser", changeUser: el }
      this.websocket.send(message);
    },
    addNewUser() {
      this.newUserWait = true;
      const message = {
        action: "addNewUser",
        newUser: this.newUser,
      }
      this.websocket.send(message);
    },
    mainFunctionAfrerEntry() {

      this.getCategories();
      this.getUsers();
      this.getTasks();

    },
    getCategories() {
      const message = {
        action: "getCategories",
      }
      this.websocket.send(message);
    },
    getUsers() {
      const message = {
        action: "getUsers",
      }
      this.websocket.send(message);
    },
    getTasks() {
      const message = {
        action: "getTasks",
      }
      this.websocket.send(message);
    },

    exit() {
      localStorage.entry_key = null;
      this.user = null;
      this.websocket.user = null;
    },
    entry() {
      this.loadingEntry = true;
      this.entryError = null;
      const message = {
        action: "entry",
        login: this.login,
        password: this.password,
      }
      this.websocket.send(message);
    },
    checkEntry() {
      if (!localStorage.entry_key) return;
      this.loadingEntry = true;

      const message = {
        action: "checkEntry",
        entry_key: localStorage.entry_key,
      }
      this.websocket.send(message);

    },
    connectionClose() {
      if (this.websocket) {
        this.websocket.close();
        this.websocket.connectionStatus = 2;
        this.websocket.connection = null;
        this.websocket.connectionRoom = false;
      }
      this.user = null,
        this.websocket = null;
    },
    listenerOffline() {
      // Пропало соединение с интернетом
      this.connectionClose();
    },
    listenerOnline() {
      // Соединение с интернетом появилось
      this.mainFunctionApp();
    },
    async mainFunctionApp() {
      await this.connectWebSocket()
    },
    // WebSocket
    async connectWebSocket() {
      this.websocket = new WebSocketConnection(WebSocketURL, "controls", this.userID, 'russian');
      await this.websocket.connect();
      await this.mainActionsWebSocket();
    },
    async entryRoom() {
      // Подключаемся к комнате
      const connectRoom = {
        action: "connectRoom",
        boardID: this.boardID,
      }
      this.websocket.send(connectRoom)
    },

    createEvent(message) {
      const newObject = { id: Date.now(), message: message };
      this.events.push(newObject);
      setTimeout(() => {
        const index = this.events.findIndex(item => item.id === newObject.id);
        if (index !== -1) {
          this.events.splice(index, 1);
        }
      }, 3000);
    },
    getTaskById(taskID) {
      for (let i in this.tasks) {
        if (this.tasks[i].task_id == taskID) return this.tasks[i];
      }
    },

    getUserById(userID) {
      for (let i in this.users) {
        console.log(this.users, userID)
        if (this.users[i].user_id == userID) return this.users[i];
      }
    },
    async mainActionsWebSocket() {

      this.websocket.connectionStatus = 1;
      this.checkConnection();

      this.checkEntry();

      this.websocket.connection.onclose = (event) => {
        this.connectionClose();
      };

      // Полученные сообщения от сервера
      this.websocket.connection.onmessage = (message) => {

        const data = JSON.parse(message.data);
        const action = data.action;
        // console.log('Получено сообщение от сервера:', data)

        if (action == "error") {
          alert(data.message);
        }

        if (action == "ping") {
          this.checkConnection();
          const event = { action: "pong" }
          this.websocket.send(event);
        }

        if (action == "sendQuestion") {
          const taskID = data.taskID;
          const messages = data.messages;
          const sendlerID = data.sendlerID;

          this.getTaskById(taskID).messages = messages;

          if ((this.isAdmin || this.isModer) && sendlerID != this.user.user_id) {
            this.getTaskById(taskID).newMessage = true;
            this.playAudioNewEvent();
          }

        }

        if(action == "updateBalance"){
          const balance = data.balance;
          this.user.balance = balance;
        }

        if (action == "changeTask") {
          const task = data.task;
          for (let i in this.tasks) {

            if (this.tasks[i].row_id == task.row_id) {

              if (this.tasks[i].status_task != task.status_task) {

                task.newTask = true;

                if (task.task_performer == this.user.user_id || task.status_task == "publish" || (this.isAdmin || this.isModer)) {
                  this.playAudioNewEvent();
                }

              }

              this.tasks[i] = task;
            }

          }
        }

        if (action == "changeFileStatus") {

          for (let i in this.tasks) {
            for (let f in this.tasks[i].files) {
              if (this.tasks[i].files[f].row_id == data.rowID) {
                this.tasks[i].files[f].status = data.status;
              }
            }
          }

        }

        if (this.user) {
          if (action == "changePassword") {
            this.newPassword = null;
            this.createEvent('Пароль был изменён');
          }

          if (action == "addNewUser" || action == "changeUser" || action == "deleteUser") {

            if (this.newUserWait) {
              this.newUser.login = null;
              this.newUser.password = null;
              this.newUser.root = 0;
              this.newUserWait = false;
            }

            if (action == "deleteUser" && data.userID == this.user.user_id) {
              this.exit();
            }

            this.getUsers();
          }

          if (action == "getUsers") {
            this.users = data.users;
          }
          if (action == "getCategories") {
            this.categories = data.categories;
          }
          if (action == "getTasks") {
            this.tasks = data.tasks;
          }

          if (action == "newTaskStart") {
            data.task.newTask = true;
            this.tasks.push(data.task);
            this.playAudioNewEvent();

            // setTimeout(() => {
            //   this.getTaskByRowId(data.task.row_id).newTask = false;
            // }, "4000");
          }

        }


        if (action == "entry") {
          this.loadingEntry = false;
          const user = data.user;
          if (!user) {
            this.entryError = data.message;
          } else {
            this.websocket.user = user;
            this.login = null;
            this.password = null;
            this.user = user;
            localStorage.entry_key = data.entry_key;
            this.mainFunctionAfrerEntry();
          }
        }

        if (action == "checkEntry") {
          this.loadingEntry = false;
          const user = data.user;
          if (user) {
            this.websocket.user = user;
            this.user = user;
            this.mainFunctionAfrerEntry();
          }
        }

      }

      this.websocket.onerror = (error) => {
        console.log("Ошибка websocket error:");
        console.log(error);
        this.websocket.connectionStatus = 2;
      };

    },
    sendFile() {

    },
    playAudioNewEvent() {
      const audio = new Audio('src/new_task.mp3');
      audio.play().then(() => {
      }).catch(error => {
        console.error('Error playing audio:', error);
      });
    },
    getCountTasksInFilter(filter) {
      if ((this.isAdmin || this.isModer) || filter == 'new') return (this.tasks.filter((elem) => elem.status_task == filter)).length;

      if (filter == 'publish') return (this.tasks.filter((elem) => elem.status_task == filter)).length;

      return (this.tasks.filter((elem) => elem.status_task == filter && elem.task_performer == this.user.user_id)).length;
    },
    getCountTasksInFilterNew(filter) {
      let count = 0;
      if ((this.isAdmin || this.isModer) || filter == 'new') {
        count = (this.tasks.filter((elem) => elem.status_task == filter && elem.newTask)).length;
      } else {

        if (filter == 'publish') {
          count = (this.tasks.filter((elem) => elem.status_task == filter && elem.newTask)).length;
        } else {
          count = (this.tasks.filter((elem) => elem.status_task == filter && elem.task_performer == this.user.user_id && elem.newTask)).length;
        }


      }

      if (count != 0) {
        return `+${count}`;
      }
    },
    checkConnection() {
      clearTimeout(this.intervalReconnection);
      this.intervalReconnection = setInterval(async () => {
        this.mainFunctionApp();
      }, "35000");
    },
    getTaskByRowId(rowID) {
      for (let i in this.tasks) {
        if (this.tasks[i].row_id == rowID) return this.tasks[i];
      }
    }
  },
  computed: {
    canTakeTask() {
      if (this.isAdmin) return true;

      for (let i in this.tasks) {
        if (this.tasks[i].task_performer == this.user.user_id && this.tasks[i].status_task == 'in_work') {
          return false;
        }
      }
      return true;
    },
    allUsers() {
      return this.users.filter((elem) => elem.user_id != this.user.user_id);
    },
    isAdmin() {
      if (this.user.root == 1) return true;
      return false;
    },
    isModer() {
      if (this.user.root == 2) return true;
      return false;
    },
    isUser() {
      if (this.user.root == 0) return true;
      return false;
    },
    tasksOrderRowId() {
      return this.tasks.sort((a, b) => b.row_id - a.row_id);
    },
    tasksFiltered() {
      if (this.tasksFilter == 'all' && (this.isAdmin || this.isModer)) return this.tasksOrderRowId;

      if (this.tasksFilter == 'all') return this.tasksOrderRowId.filter((elem) => elem.status_task != 'new' && elem.task_performer == this.user.user_id)

      return this.tasksOrderRowId.filter((elem) => elem.status_task == this.tasksFilter)
    }
  },
  watch: {
  },
}
</script>
<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#bsTask {
  height: 100vh;
  width: 100%;
  font-family: sans-serif;
}

.content {
  padding: 10px;
  padding-left: 300px
}

.lost-error {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 99999999999999999999;
  backdrop-filter: blur(11px);
}

.app {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  height: 100%;
  justify-content: center;
  min-width: 300px;
  background: #e6e6e6;
  position: fixed;
}

.viewer {
  width: 100%;
  padding: 20px;
}

.panelHeader {
  font-weight: bold;
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  align-items: center;
}

.panelFilters {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  gap: 10px;
}

.panelFiltersElem,
.panelHeader div {
  transition: all .3s;
  cursor: pointer;
}

.panelFiltersElem:hover,
.panelHeader div:hover {
  opacity: .5;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #d3d3d3;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: rgb(255, 255, 255);
  padding: 20px 30px;
  width: 90%;
  max-width: 300px;
  border-radius: 5px;
  position: relative;
}

.modalContentElem {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

input,
textarea {
  border-radius: 5px;
  padding: 5px;
  margin-top: 4px;
  border: 1px solid lightgrey;
}

button {
  padding: 8px;
  border: none;
  cursor: pointer;
  transition: all .3s;
  border-radius: 5px;
  margin-top: 10px;
  background: #789fcf;
  color: white;
  font-weight: bold;

}

button:hover {
  opacity: .5;
}

.modalContentLoading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  backdrop-filter: blur(2px);
  font-weight: bold;
}

.modalContentError {
  font-weight: bold;
  text-align: center;
  color: red;
  font-size: 12px;
}

.disabledBtn {
  opacity: .5;
  pointer-events: none;
}

form {}


.contentSettings {
  margin-top: 20px;
}

.contentSettings h3 {
  margin-bottom: 20px;

}

.contentSettingsUsers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
}

.contentSettingsUsersElem {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  gap: 10px;

  justify-content: space-between;
}

.contentSettingsUsersElemBtns {
  display: flex;
  justify-content: space-between;
}

.btnNegative {
  padding: 5px;
  background: #ff6464;
}

button svg {
  width: 20px;
}

.contentSettingsPassword {
  display: flex;
  gap: 10px;
  align-items: center;
}

.contentSettingsPassword input,
.contentSettingsPassword button {
  margin: 0;
}

.events {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.eventsElem {
  background: #93939399;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.contentTasks {
  overflow: auto;
  border-radius: 10px;
  max-height: 90vh;
  height: 100%;
}

table {
  width: 100%;
}

table tr {
  border: 2px solid #d4d4d4;
}

table th,
table td {
  border-left: 2px solid #d4d4d4;
  border-rigth: 2px solid #d4d4d4;
}

thead {
  font-size: 16px;
  background: #d4d4d4;
}

tbody {
  font-size: 14px;
}

thead th,
tbody th {
  padding: 5px;
}

tbody th {
  border-bottom: 3px solid #d4d4d4;
}

tbody tr {
  transition: all .1s;
}

tbody tr:hover {
  background-color: #6b6b6b26;
}

.contentTasksHeaderDescription {
  width: 500px;
}

.contentTasksElemDescription {
  text-align: start;
  word-break: break-word;
}

.panelFiltersElem {
  transition: all .3s;
  border-bottom: 2px solid transparent;
}

.panelFiltersSelected {
  font-weight: bold;
  border-bottom: 2px solid black;
}

.newTask {
  background-color: #f3ffb6;
}

.taskFile svg,
.taskActionsElem svg {
  width: 20px;
}

.taskFiles {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  flex-direction: row;
}

.taskDetails {
  display: flex;
  flex-direction: column;
}

.taskDetailsChat {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  max-height: 190px;
  overflow: auto;
}

.taskDetailsChatReview {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  background: #789fcf;
  color: black;
}

.taskDetailsChatQuestion {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background: #d4d4d4;
  color: black;
  margin-left: auto;
}

.taskDetailsSend {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.taskDetailsSend button,
.taskDetailsSend input {
  margin: 0
}

.taskDetailsChatMessage {
  display: flex;
  width: 70%;
  padding: 10px;
  box-shadow: 0px 0px 10px -1px black;
  border-radius: 10px;
  position: relative;
  margin-top: 20px;
  font-size: 12px;
}

.taskDetailsChatMessageSendler {
  position: absolute;
  top: -20px;
  font-size: 14px;
  color: #727272;
  width: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-end;
}

.taskDetailsChatMessageSendlerDate {
  font-size: 10px;
}

.newUserAccessCategory {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  max-height: 200px;
  overflow: auto;
}

.newUserAccessCategoryElem {
  border-radius: 10px;
  background: #e6e6e6;
  padding: 5px;
  transition: all .3s;
  cursor: pointer;
}

.newUserAccessCategoryElem.selectedElem {
  background: #789fcf;
  color: white;
}

.newUserAccessCategoryElem:hover {
  opacity: .5;
}

.accessCategories {

  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0;

}

.accessCategoriesElem {}

.accessCategoriesTitle {
  margin-top: 20px
}

.modalEditTask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  backdrop-filter: blur(5px);
  z-index: 9999;
  align-items: center;
  justify-content: center;
}

.modalEditTaskContent {
  position: relative;
  width: 90%;
  max-width: 400px;
  height: 90%;
  max-height: 500px;
  overflow: auto;
  background: #e6e6e6;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalEditTaskContent svg {
  width: 20px;
  right: 15px;
  top: 15px;
  position: absolute;
  transition: all .3s;
  cursor: pointer;
  z-index: 99;
}

.modalEditTaskContent svg:hover {
  opacity: .5;
}
</style>